.report {
  .title {
    font-family: Open Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
  }

  .info {
    font-family: Open Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;

    ul {
      list-style: none;
      padding: 0;
      margin-left: 15px;
      margin-top: 30px;

      li {
        margin-top: 15px;
        margin-bottom: 15px;
      }
    }
  }

  .filename {
    margin-left: 15px;
    margin-top: 40px;
    margin-bottom: 30px;
    a {
      font-family: Open Sans;
      font-size: 18px;
      font-style: normal;
      font-weight: 300;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: left;
      color: #000000;
      text-decoration: none;
    }
  }

  hr {
    color: #c4c4c4;
  }

  .table {
    border-collapse: collapse;
    border-radius: 10px;
    border-style: hidden; /* hide standard table (collapsed) border */
    box-shadow: 0 0 0 1px #4861ad; /* this draws the table border  */
    width: 100%;

    td {
      border: 1px solid #4861ad;
      text-align: center;
      width: 50%;
    }

    .table-title {
      font-family: Open Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: center;
    }

    .table-value {
      font-family: Open Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: center;
    }
  }
}
