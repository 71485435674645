.upload {
  .title {
    font-family: Open Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
  }

  .dropzone-container {
    height: 150px;
    border: 1px dashed #5a5959;
    border-radius: 10px;
  }

  p {
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
  }

  .upload-btn {
    font-family: Open Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    height: 50px;
    border-radius: 10px;
    text-transform: none;
    background: "#4861AD";
  }

  .upload-btn.completed {
    font-size: 20px;
  }

  .uploaded-file {
    h3 {
      font-family: Open Sans;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: left;
      margin-bottom: 20px;
      margin-top: 0;
      color: #4861ad;
    }
    .uploaded-file-name {
      font-family: Open Sans;
      font-size: 18px;
      font-style: normal;
      font-weight: 300;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: left;
    }
  }

  .upload-progress {
    h4 {
      font-family: Open Sans;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: left;
    }
  }
}
