.header {
  width: 200px;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;

  .logo {
    margin-top: 35px;
    margin-bottom: 30px;
    img {
      width: "140px";
    }
  }

  // .button-group {
  //   display: flex;
  //   flex-direction: column;
  // }

  .icon-button {
    margin-top: 55px;
    width: 70px;
    height: 70px;
    border-radius: 20px;
  }

  .tab-button {
    font-family: Open Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    // text-align: left;
    padding: 15px;
    color: #5a5959;
    border-radius: 10px;
    width: 100%;
    justify-content: start !important;
    text-transform: none;
    border: none;

    &:hover {
      background: rgba(72, 97, 173, 0.1);
      color: #4861ad;
    }

    &.active {
      background: rgba(72, 97, 173, 0.1);
      color: #4861ad;
    }
  }
}
