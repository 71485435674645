.date-range-picker {
  .MuiInput-underline::before,
  .MuiInput-underline::after {
    border: none !important;
  }

  .MuiSelect-icon {
    color: white;
  }
}
