.patient-panel {
  .avatar {
    width: 100px;
    border-radius: 50%;
  }

  .name {
    font-family: Open Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
  }

  .record {
    display: flex;
    align-items: center;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;

    margin-bottom: 20px;

    svg {
      color: #4861ad;
      font-size: 20px;
      margin-right: 23px;
    }
  }

  .history {
    .title {
      font-family: Open Sans;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0em;
    }
  }
}
